<script>
import Cookies from 'js-cookie'
import { mapActions } from 'vuex';
import { cannotAccessList } from '@/assets/cannotAccessList';

export default {
  name: 'TokenMixin',
  methods: {
    ...mapActions(['setDialogMessage', 'setShowDialog']),
    getCurrentUserData() {
      const data = Cookies.get('YAU_CHIN_CMS_LID')

      if (data !== undefined && data !== null && data !== '') {
        return JSON.parse(Cookies.get('YAU_CHIN_CMS_LID'))
      }

      return {}
    },
    /**
     * @param {Object} data - { id: -1, user_type: '', username: '' }
     */
    setUserData(data) {
      const userData = this.getCurrentUserData()
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('YAU_CHIN_CMS_LID')
      }

      Cookies.set('YAU_CHIN_CMS_LID', JSON.stringify(data), {
        path: '/',
        expires: new Date(new Date().getTime() + 2 * 60 * 60 * 1000), // expires after 2 hour
        sameSite: 'strict',
        secure: true,
      })
    },
    getUserType() {
      const userData = this.getCurrentUserData();
      return userData.user_type;
    },
    removeUserCookies() {
      const userData = this.getCurrentUserData();
      if (this.$validate.DataValid(userData)) {
        Cookies.remove('YAU_CHIN_CMS_LID')
      }
    },
    checkUserData() {
      let resultType = false;
      const userData = this.getCurrentUserData();

      if (this.$validate.DataValid(userData)) {
        if (
          this.$validate.DataValid(userData.id) &&
          this.$validate.DataValid(userData.username) &&
          this.$validate.DataValid(userData.user_type)
        ) {
          resultType = true;
        }
      }

      if (!resultType) {
        this.forceLogout()
      } else {
        const linkName = this.$route.name;
        if (linkName && cannotAccessList[linkName] && cannotAccessList[linkName].length > 0 && cannotAccessList[linkName].includes(userData.user_type)) {
          resultType = false;
          this.setDialogMessage({
            message: 'noPermission',
            userType: userData.user_type
          });
          this.setShowDialog(true);
          this.$store.dispatch('toggleLoadingPage', false)
        }
      }

      return resultType;
    },

   
    async forceLogout(alert = true) {
      this.$store.dispatch('toggleLoadingPage', true);
      this.removeUserCookies();

      if (alert === true) {
        this.$store.dispatch('toggleAlertMessage', {
          show: true,
          message: '登入過期，請重新登入',
          type: 'error',
          refresh: false,
          redirect: 'login',
        })
      } else {
        this.$router.replace({ name: 'login' });
        this.$store.dispatch('toggleLoadingPage', false);
      }
     
    },
  },
}
</script>
