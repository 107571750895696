import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'

import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router'
import api from './api'
import TokenMixin from '@/mixins/TokenMixin'
import PageHeaderSlot from './components/PageHeaderSlot.vue'
import Fetcher from './prototype/Fetcher'
import CommonFn from './prototype/CommonFn'
import Formatter from './prototype/Formatter'
import Validator from './prototype/Validator'

Vue.config.productionTip = false

Vue.use(api)
Vue.use(CommonFn)
Vue.use(Validator)
Vue.use(Formatter)
Vue.use(Fetcher)
Vue.mixin(TokenMixin)

Vue.component('PageHeaderSlot', PageHeaderSlot)

Vue.prototype.$mediaPath = 'https://media.yauchinproperty.com/';


const appointmentTypeOptions = [
  { text: '咨詢會面', value: 'consulting' },
  { text: '視察樓宇', value: 'inspect_buildings' },
];
Vue.prototype.$appointmentTypeOptions = appointmentTypeOptions;
Vue.filter('appointmentType', function (value) {
  if (!value) return '';

  const target = appointmentTypeOptions.find(e => e.value === value);

  return target ? target.text : value;
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
